import { CfopFilters, CodFiscal } from '../../protocols';

export default async function filtrarCFOP(
  cfops: CodFiscal[],
  filters: CfopFilters[],
): Promise<CodFiscal[]> {
  let filteredCfop: CodFiscal[] = cfops;

  // Juntamos todos os filtros do tipo startsWith
  const startsWithFilters = filters.filter(
    (filter) => filter.type === 'startsWith',
  );

  // Aplicamos todos os filtros do tipo startsWith de uma vez
  if (startsWithFilters.length > 0) {
    filteredCfop = filteredCfop.filter((cfop) => {
      const cfopStr = String(cfop.cfop);
      return startsWithFilters.some((filter) => {
        return filter.toFilter.some((filterValue) =>
          cfopStr.startsWith(filterValue),
        );
      });
    });
  }

  // Juntamos todos os filtros do tipo equals
  const equalsFilters = filters.filter((filter) => filter.type === 'equals');
  // Aplicamos todos os filtros do tipo equals de uma vez
  if (equalsFilters.length > 0) {
    filteredCfop = filteredCfop.filter((cfop) => {
      return equalsFilters.some((filter) => {
        if (filter.flag) {
          return filter.toFilter.includes(cfop[filter.flag]);
        }
        return filter.toFilter.includes(cfop.cfop);
      });
    });
  }

  // Juntamos todos os filtros do tipo diferent
  const differentFilters = filters.filter(
    (filter) => filter.type === 'diferent',
  );

  // Aplicamos todos os filtros do tipo diferent de uma vez
  if (differentFilters.length > 0) {
    filteredCfop = filteredCfop.filter((cfop) => {
      return differentFilters.every((filter) => {
        return !filter.toFilter.includes(cfop.cfop);
      });
    });
  }

  return filteredCfop;
}
