import api from '~/services/api';
import {
  CapaData,
  DadosErpDoFornecedor,
  ParamentrosValidacao,
  ProductDataToTable,
} from '../../protocols';
import ValidarTributacao from './validarTributacao';

interface ValidarTributacaoProps {
  vCodTrib?: string;
  CST?: string;
  pICMS?: string;
  pRedBC?: string;
  pRedBCST?: string;
  pICMSST?: string;
  pST?: string;
  vTotProd?: string;
  vBCICMS?: string;
  CSOSN?: string;
  vICMSST?: string;
  itensForValidation: ProductDataToTable;
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
}

export default async function validaItensDoXML(
  itensForValidation: ProductDataToTable,
  dadosDaCapa: CapaData,
  parametrosValidacao: ParamentrosValidacao,
  dadosErpDoFornecedor: DadosErpDoFornecedor,
  revalida?: boolean,
  nfValidada?: boolean,
): Promise<ProductDataToTable> {
  const vCodTribEnt = itensForValidation.cod_trib_entrada;
  // const vTipoTrib = 0;
  // const CodIdCTB = 0;
  // const Amostra = false;
  let vCST: string | undefined = '';
  let vUnidade = '';
  const validada_confirmada =
    nfValidada !== undefined ? nfValidada : dadosDaCapa.tipo_status >= 2;

  if (validada_confirmada) {
    return itensForValidation;
  }
  if (!itensForValidation.statusDaValidacao || revalida) {
    itensForValidation.statusDaValidacao = [];
  }

  vUnidade = itensForValidation.uCom;

  // Validação Unidade de Compra
  if (itensForValidation.qtd_unid_compra_forn > 0) {
    if (
      itensForValidation.flg_ipv &&
      itensForValidation.uCom !== 'KG' &&
      itensForValidation.uTrib === 'KG'
    ) {
      vUnidade = itensForValidation.uTrib;
    }
    const { data } = await api.post('/busca-qtd-embalagem-compra', {
      cod_produto: itensForValidation.cod_produto,
      cod_fornecedor: dadosErpDoFornecedor.cod_fornecedor.value,
      cod_loja: dadosDaCapa.cod_loja,
      des_unidade_compra: vUnidade,
    });
    if (data.success && data.data.length > 0) {
      if (data.data[0].qtd_embalagem_compra > 0) {
        itensForValidation.des_unidade_compra = vUnidade
          .substring(0, 2)
          .toUpperCase();
        itensForValidation.qtd_embalagem_compra =
          data.data[0].qtd_embalagem_compra;
      } else {
        itensForValidation.des_unidade_compra =
          itensForValidation.des_unid_compra_forn;
        itensForValidation.qtd_embalagem_compra =
          itensForValidation.qtd_unid_compra_forn;
      }
    }
  }

  if (
    itensForValidation.flg_ipv &&
    itensForValidation.des_unidade_compra !== 'KG'
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 103,
      messageStatus: 'Produto pesável precisa ser unidade KG',
      origem: 'CAD',
      restricao: 'INF',
    });
  }
  if (
    String(vUnidade.substring(0, 2)).toUpperCase() !==
    String(itensForValidation.des_unidade_compra).toUpperCase()
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 100,
      messageStatus: 'Unidade de Compra divergente',
      origem: 'CAD',
      restricao: 'INF',
    });
  }

  // Validação Fora de linha
  if (itensForValidation.flg_fora_linha) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 3,
      messageStatus: 'Produto Fora de Linha',
      origem: 'MIX',
      restricao: 'BLOQ',
    });
  }
  if (
    Number(itensForValidation.pMVAST) > 0 &&
    Number(itensForValidation.pMVAST) !==
      Number(itensForValidation.per_pauta_iva_ncm)
  ) {
    if (
      parametrosValidacao.icms_st === false &&
      parametrosValidacao.icms_op === false
    ) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 309,
        messageStatus: 'IVA divergente',
        origem: 'TRIB',
        restricao: 'INF',
      });
    }
  }

  // Validação Mix
  if (itensForValidation.flg_inativo) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 2,
      messageStatus: 'Produto fora do Mix',
      origem: 'MIX',
      restricao: 'BLOQ',
    });
  }
  if (itensForValidation.flg_fora_linha) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 3,
      messageStatus: 'Produto Fora de Linha',
      origem: 'MIX',
      restricao: 'BLOQ',
    });
  }

  if (
    itensForValidation.flg_gtin_vinculado === false &&
    itensForValidation.cEAN !== 'SEM GTIN'
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 201,
      messageStatus: 'GTIN não vinculado ao produto.',
      origem: 'GTIN',
      restricao: 'INF',
    });
  }

  // Valida cod associado

  const veryAssocData = {
    cod_gtin: itensForValidation.cod_gtin_principal,
    cod_loja: dadosDaCapa.cod_loja,
  };
  const {
    data: { data },
  } = await api.post('/verificaProdutoAssociado', veryAssocData);
  if (
    data &&
    data.length > 0 &&
    data[0].cod_associado !== '' &&
    data[0].cod_associado !== null &&
    itensForValidation.cEAN !== 'SEM GTIN'
  ) {
    const response = await api.post('/buscaCodigoDeBarras', {
      cod_produto: data[0].cod_produto,
    });
    if (
      response.data.data &&
      response.data.data.length > 0 &&
      response.data.data[0].cod_gtin_principal !== ''
    ) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 201,
        messageStatus: 'Produto Associado. Entrada somente no Produto-Pai',
        origem: 'GTIN',
        restricao: 'INF',
      });
    }
  }

  if (
    itensForValidation.cEAN.length >= 14 &&
    itensForValidation.cEAN !== 'SEM GTIN'
  ) {
    const response = await api.post('/verificaProdutoAssociado', {
      cod_gtin: itensForValidation.cEAN,
      cod_loja: dadosDaCapa.cod_loja,
    });
    if (
      response.data.data === undefined ||
      response.data.data === '' ||
      response.data.data.lenght <= 0
    ) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 200,
        messageStatus: 'Produto com GTIN-14 deve estar associado a um produto.',
        origem: 'GTIN',
        restricao: 'BLOQ',
      });
    }
  }

  // Validação NCM
  if (itensForValidation.NCM === '00') {
    itensForValidation.NCM = '00000000';
  }
  if (
    Number(itensForValidation.pMVAST) > 0 &&
    Number(itensForValidation.pMVAST) !==
      Number(itensForValidation.per_pauta_iva_ncm)
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 309,
      messageStatus: '% IVA divergente',
      origem: 'TRIB',
      restricao: 'INF',
    });
  }
  if (
    itensForValidation.CEST !== '' &&
    itensForValidation.num_cest !== itensForValidation.CEST
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 308,
      messageStatus: 'CEST divergente',
      origem: 'TRIB',
      restricao: 'INF',
    });
  }

  if (
    itensForValidation.num_ncm !== itensForValidation.NCM &&
    itensForValidation.num_ncm !== ''
  ) {
    if ([1, 3, 5].includes(Number(itensForValidation.tipo_especie))) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 307,
        messageStatus: 'NCM divergente',
        origem: 'TRIB',
        restricao: 'INF',
      });
    } else {
      itensForValidation.statusDaValidacao.push({
        codStatus: 311,
        messageStatus: 'NCM divergente',
        origem: 'TRIB',
        restricao: 'INF',
      });
    }
  }
  if (itensForValidation.NCM === '') {
    itensForValidation.statusDaValidacao.push({
      codStatus: 101,
      messageStatus: 'Produto sem NCM informado',
      origem: 'CAD',
      restricao: 'BLOQ',
    });
  }

  // Validação TRIB
  if (
    Number(itensForValidation.pICMS) === 18 &&
    Number(itensForValidation.pRedBCST) === 77 &&
    (itensForValidation.icsmCST === '90' || itensForValidation.icsmCST === '20')
  ) {
    vCST = itensForValidation.cod_sit_tributaria_ent.substring(2, 3);
  } else {
    vCST = itensForValidation.icsmCST;
  }
  /**
   * Pegar dados do ERP
   */
  const props: ValidarTributacaoProps = {
    vCodTrib: String(vCodTribEnt),
    CST: vCST,
    pICMS: itensForValidation.pICMS,
    pRedBC: itensForValidation.pRedBC,
    pRedBCST: itensForValidation.pRedBCST,
    pICMSST: itensForValidation.pICMSST,
    pST: itensForValidation.pST,
    vTotProd: itensForValidation.vProd,
    vBCICMS: itensForValidation.vBC,
    CSOSN: itensForValidation.CSOSN,
    vICMSST: itensForValidation.vICMSST,
    itensForValidation,
    dadosDaCapa,
    parametrosValidacao,
    dadosErpDoFornecedor,
  };

  itensForValidation = await ValidarTributacao(props);

  // CFOP
  if (itensForValidation.cfop === '') {
    itensForValidation.statusDaValidacao.push({
      codStatus: 310,
      messageStatus: 'CFOP não informado',
      origem: 'TRIB',
      restricao: 'BLOQ',
    });
  }

  // remover status duplicados

  const result = itensForValidation.statusDaValidacao.reduce(
    (unique: any, o) => {
      if (!unique.some((obj: any) => obj.codStatus === o.codStatus)) {
        unique.push(o);
      }
      return unique;
    },
    [],
  );

  itensForValidation.statusDaValidacao = result;

  return itensForValidation;
}
