import { toast } from 'react-toastify';
import api from '~/services/api';
import { verificarValor } from './verificarValor';

export default async function validaGtinVinculado(
  cod_produto: string,
  cEAN: string,
  cEANTrib: string,
): Promise<boolean> {
  if (cEAN.trim() === '' || cEANTrib.trim() === '') {
    return true;
  }

  let cEANFinal = '';
  if (cEAN.length > 13) {
    if (cEANTrib && cEANTrib.length <= 13) {
      cEANFinal = cEANTrib;
    }
  } else if (!cEAN && cEANTrib) {
    if (cEANTrib.length > 13) {
      if (cEANTrib.length === 14) {
        cEANFinal = cEANTrib.substring(1, 14);
      }
    } else {
      cEANFinal = cEANTrib;
    }
  } else {
    cEANFinal = cEAN;
  }

  if (cEANFinal) {
    cEANFinal = verificarValor(cEANFinal).replace(/^0+/, '');
  }

  try {
    const response = await api.get('/valida-gtin-vinculado', {
      params: {
        cod_ean: cEANFinal,
        cod_produto,
      },
    });
    if (response.data.data.length) {
      return true;
    }
    if (cEANTrib) {
      const responseTrib = await api.get('/valida-gtin-vinculado', {
        params: {
          cod_ean: cEANTrib,
          cod_produto,
        },
      });
      return responseTrib.data.data.length !== 0;
    }
    return false;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return false;
    }
    toast.error(String(error));
    return false;
  }
}
