import api from '~/services/api';

export default async function retornaCodigoDaTributacao(
  vCodTrib: string,
  vTipoTrib: number,
  vCST: string,
): Promise<string> {
  let result = '0';
  const {
    data: { data },
  } = await api.post('/tributacao/tipoTributacao', {
    tipo_tributacao: Number(vTipoTrib),
    cod_situacao_tributaria: vCST,
  });

  if (!data || data.length <= 0) {
    return result;
  }
  result = data[0].cod_tributacao;
  return result;
}
