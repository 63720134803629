import api from '~/services/api';
import { DadosErpDoFornecedor, ProductDataToTable } from '../../protocols';
import { toast } from 'react-toastify';

interface Props {
  codXml: number;
  tipoIcmsSt: boolean;
  TipoIcmsOp: boolean;
  codPerfil: number;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
  itensDaTabela: ProductDataToTable[];
}

export default async function alteraStatusdoXml({
  codXml,
  tipoIcmsSt,
  TipoIcmsOp,
  codPerfil,
  dadosErpDoFornecedor,
  itensDaTabela,
}: Props): Promise<boolean> {
  let capaIsInvalid = false;
  let itemIsInvalid = false;
  /**
   * Validar Capa
   * Caso algum dos campos 'num_rg_ie', 'num_cep', 'des_uf' esteja invalido
   * o status sera alterado para 1 - Conflito
   */
  const requiredCapaFields = Object.entries(dadosErpDoFornecedor)
    .filter((campo) => ['num_rg_ie', 'num_cep', 'des_uf'].includes(campo[0]))
    .filter((campo) => campo[1].invalid);
  if (requiredCapaFields.length > 0) {
    capaIsInvalid = true;
  }
  /**
   * Validar Itens
   * Caso algum dos itens possua validação pendente
   * e o códito do status seja de 1 à 99 ou
   * a restricao seja NE “Não Encontrado“ / BLOQ “Bloqueador” o status sera alterado para 1 - Conflito
   */
  const itensThatHasValidation = itensDaTabela.filter((item) => {
    if (Array.isArray(item.statusDaValidacao)) {
      const itemTipoStatus = item.statusDaValidacao.filter(
        (status) =>
          status.codStatus < 99 ||
          status.restricao === 'NE' ||
          status.restricao === 'BLOQ',
      );
      return item.statusDaValidacao.length > 0 && itemTipoStatus.length > 0;
    }
    return false;
  });

  if (itensThatHasValidation.length > 0 || itensDaTabela.length === 0) {
    itemIsInvalid = true;
  }
  /**
   * Adicionar validação de CFOP BONiFICAÇÃO
   */
  try {
    await api.put('/altera-status-xml', {
      cod_xml: codXml,
      tipo_status: capaIsInvalid || itemIsInvalid ? 1 : 2,
      icms_st: tipoIcmsSt,
      icms_op: TipoIcmsOp,
      cod_perfil: codPerfil,
    });
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return false;
    }
    toast.error(String(error));
  }
  return !(capaIsInvalid || itemIsInvalid);
}
