export const getKeyIdByPrefix = (objectName: any, keyName: any): any => {
  const size = keyName.length;

  const keyId = Object.keys(objectName).find(
    (key) => key.substring(0, size) === keyName,
  );
  return keyId;
};

export const getKeyByPrefix = (objectName: any, keyName: any) => {
  return objectName[getKeyIdByPrefix(objectName, keyName)];
};

export const fillFieldsDefaults = (objectName: any, objDefaults: any) => {
  let i = 0;
  const keys = Object.keys(objDefaults),
    l = keys.length;
  for (; i < l; i++) {
    if (objectName[keys[i]] === undefined) {
      objectName[keys[i]] = objDefaults[keys[i]];
    }
  }
  return objectName;
};

export const getFirstKeyId = (objectName: any) => {
  return Object.keys(objectName)[0];
};

export const getFirstKey = (objectName: any) => {
  return objectName[getFirstKeyId(objectName)];
};
