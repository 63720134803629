/* eslint-disable @typescript-eslint/no-unused-vars */
import api from '~/services/api';
import {
  CapaData,
  ParamentrosValidacao,
  DadosErpDoFornecedor,
  ProductDataToTable,
} from '../../protocols';
import defineCFOP from './defineCFOP';
import existeTributacaoDoNCMUF from './existeTributacaoDoNCMUF';
import retornaCodigoDaTributacao from './retornaCodigoDaTributacao';
import retornaTipoDaTributacao from './retornaTipoDaTributacao';

interface ValidarTributacao {
  vCodTrib?: string;
  CST?: string;
  pICMS?: string;
  pRedBC?: string;
  pRedBCST?: string;
  pICMSST?: string;
  pST?: string;
  vTotProd?: string;
  vBCICMS?: string;
  CSOSN?: string;
  vICMSST?: string;
  itensForValidation: ProductDataToTable;
  parametrosValidacao: ParamentrosValidacao;
  dadosDaCapa: CapaData;
  dadosErpDoFornecedor: DadosErpDoFornecedor;
}
export default async function ValidarTributacao(
  props: ValidarTributacao,
): Promise<ProductDataToTable> {
  const {
    CST,
    pICMS,
    pRedBC,
    pRedBCST,
    pICMSST,
    pST,
    vTotProd,
    vBCICMS,
    CSOSN,
    vICMSST,
    itensForValidation,
    parametrosValidacao,
    dadosDaCapa,
    dadosErpDoFornecedor,
  } = props;
  let { vCodTrib } = props;
  let pRedBCCalc = 0;

  if (CST === '00' && Number(CSOSN) === 0) {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: pICMS,
      cod_situacao_tributaria: '00',
      val_reducao: 0,
    });
    if (!data || data.length <= 0) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 304,
        messageStatus: 'Tributação do XML não encontrada',
        origem: 'TRIB',
        restricao: 'BLOQ',
      });
    } else {
      vCodTrib = data[0].cod_tributacao;
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
      itensForValidation.des_trib_ent = data[0].des_tributacao;
      const responseTribEntrada = await api.post('/buscaTributacaoEntrada', {
        cod_loja: dadosDaCapa.cod_loja,
        des_sigla: dadosDaCapa.des_uf,
        num_ncm: itensForValidation.num_ncm,
        cod_produto: itensForValidation.cod_produto,
      });
      if (
        responseTribEntrada.data.data &&
        responseTribEntrada.data.data.length <= 0
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 301,
          messageStatus: `Tributação do XML não esta vinculada ao NCM do Produto - Cód Tributação: ${data[0].cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      } else if (
        responseTribEntrada.data.data[0].cod_trib_entrada !==
        data[0].cod_tributacao
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 302,
          messageStatus: `Tributação do XML difere da cadastrada no NCM - Cód Tributação: ${data[0].cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      }
    }
  }
  if (CST === '10') {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: Number(pICMS) > 0 ? pICMS : pICMSST,
      cod_situacao_tributaria: '60',
      val_reducao: 0,
    });
    if (!data || data.length <= 0) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 300,
        messageStatus: 'Tributação do XML não encontrada(CST: 060)',
        origem: 'TRIB',
        restricao: 'BLOQ',
      });
    } else {
      vCodTrib = data[0].cod_tributacao;
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
      itensForValidation.des_trib_ent = data[0].des_tributacao;
      const responseTribEntrada = await api.post('/buscaTributacaoEntrada', {
        cod_loja: dadosDaCapa.cod_loja,
        des_sigla: dadosDaCapa.des_uf,
        num_ncm: itensForValidation.num_ncm,
        cod_produto: itensForValidation.cod_produto,
      });
      if (
        responseTribEntrada.data.data &&
        responseTribEntrada.data.data.length <= 0
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 301,
          messageStatus: `Tributação do XML não esta vinculada ao NCM do Produto - Cód Tributação: ${data[0].cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      } else if (
        responseTribEntrada.data.data[0].cod_trib_entrada !==
        data[0].cod_tributacao
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 302,
          messageStatus: `Tributação do XML difere da cadastrada no NCM - Cód Tributação: ${data[0].cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      }
    }
  }
  if (CST === '20') {
    if (dadosErpDoFornecedor.des_uf.value === 'GO' && Number(vTotProd) > 0) {
      pRedBCCalc = 100 - (Number(vBCICMS) * 100) / Number(vTotProd);
    } else {
      pRedBCCalc = Number(pRedBC);
    }
    let apiResponse = null;
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: pICMS,
      cod_situacao_tributaria: '20',
      val_reducao: parseFloat(pRedBCCalc.toFixed(2)),
    });
    apiResponse = data;
    if (apiResponse && apiResponse.length <= 0) {
      const response = await api.post('/buscaTributacao', {
        val_icms: pICMS,
        cod_situacao_tributaria: '20',
        val_reducao: 100 - parseFloat(pRedBCCalc.toFixed(2)),
      });
      apiResponse = response.data.data;
    }
    if (apiResponse && apiResponse.length <= 0) {
      const response = await api.post('/buscaTributacao', {
        val_icms: pICMS,
        cod_situacao_tributaria: '20',
        val_reducao: 100 - parseFloat(Number(pRedBC).toFixed(2)),
      });
      apiResponse = response.data.data;
    }
    if (apiResponse && apiResponse.length <= 0) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 300,
        messageStatus: 'Tributação do XML não encontrada(CST: 020).',
        origem: 'TRIB',
        restricao: 'BLOQ',
      });
    } else {
      const QryVerifProdTrib = await api.post('/buscaTributacaoEntrada', {
        cod_loja: dadosDaCapa.cod_loja,
        des_sigla: dadosDaCapa.des_uf,
        num_ncm: itensForValidation.num_ncm,
        cod_produto: itensForValidation.cod_produto,
      });
      if (
        QryVerifProdTrib.data.data &&
        QryVerifProdTrib.data.data.length <= 0
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 301,
          messageStatus: `Tributação do XML não esta vinculada ao NCM do Produto. - Cód Tributação: ${apiResponse[0]?.cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      } else if (
        QryVerifProdTrib.data.data[0].cod_trib_entrada !==
        apiResponse[0]?.cod_tributacao
      ) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 302,
          messageStatus: `Tributação do XML difere da cadastrada no NCM - Cód Tributação: ${apiResponse[0]?.cod_tributacao}`,
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      }
    }
  }

  if (CST === '30') {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: pICMS,
      cod_situacao_tributaria: '30',
      val_reducao: parseFloat(Number(pRedBC).toFixed(2)),
    });
    if (!data || data.length <= 0) {
      const newData = await api.post('/buscaTributacao', {
        val_icms: pICMS,
        cod_situacao_tributaria: '30',
        val_reducao: 100 - parseFloat(Number(pRedBC).toFixed(2)),
      });
      if (!newData.data.data || newData.data.data.length <= 0) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 300,
          messageStatus: 'Tributação do XML não encontrada(CST: 030)',
          origem: 'TRIB',
          restricao: 'INF',
        });
      } else {
        vCodTrib = newData.data.data[0].cod_tributacao;
        itensForValidation.cod_trib_entrada = Number(vCodTrib);
        itensForValidation.des_trib_ent = data[0].des_tributacao;
        const QryVerifProdTrib = await api.post('/buscaTributacaoEntrada', {
          cod_loja: dadosDaCapa.cod_loja,
          des_sigla: dadosDaCapa.des_uf,
          num_ncm: itensForValidation.num_ncm,
          cod_produto: itensForValidation.cod_produto,
        });

        if (
          QryVerifProdTrib.data.data &&
          QryVerifProdTrib.data.data.length <= 0
        ) {
          itensForValidation.statusDaValidacao.push({
            codStatus: 300,
            messageStatus: `Tributação do XML não esta vinculada ao NCM do Produto - Cód Tributação: ${newData.data.datacod_tributacao}`,
            origem: 'TRIB',
            restricao: 'BLOQ',
          });
        } else if (
          QryVerifProdTrib.data.data[0].cod_trib_entrada !==
          newData.data.data[0].cod_tributacao
        ) {
          itensForValidation.statusDaValidacao.push({
            codStatus: 300,
            messageStatus: `Tributação do XML difere da cadastrada no NCM - Cód Tributação: ${newData.data.datacod_tributacao}`,
            origem: 'TRIB',
            restricao: 'BLOQ',
          });
        }
      }
    }
  }
  if (
    CST === '60' &&
    [0, 2].includes(Number(itensForValidation.tipo_trib_ent))
  ) {
    itensForValidation.statusDaValidacao.push({
      codStatus: 303,
      messageStatus:
        'Tributação do XML(Substitução) difere do cadastro(Tributado)',
      origem: 'TRIB',
      restricao: '',
    });
    if (parametrosValidacao.icms_st) {
      const {
        data: { data },
      } = await api.post('/buscaTributacao', {
        val_icms: pICMS,
        cod_situacao_tributaria: '60',
        val_reducao: parseFloat(Number(pRedBC).toFixed(2)),
      });
      if (data && data.length > 0) {
        vCodTrib = data[0].cod_tributacao;
        itensForValidation.cod_trib_entrada = Number(vCodTrib);
        itensForValidation.des_trib_ent = data[0].des_tributacao;
      }
    }
  }
  if (CST === '60' && parametrosValidacao.icms_st) {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: Number(pST) > 0 ? Number(pST) : Number(pICMSST),
      cod_situacao_tributaria: '60',
      val_reducao: parseFloat(Number(pRedBC).toFixed(2)),
    });
    if (data && data.length > 0) {
      vCodTrib = data[0].cod_tributacao;
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
      itensForValidation.des_trib_ent = data[0].des_tributacao;
    }
  }
  if (CST === '90') {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: pICMS,
      cod_situacao_tributaria: '90',
      val_reducao: parseFloat(Number(pRedBC).toFixed(2)),
    });
    if (data && data.length > 0) {
      vCodTrib = data[0].cod_tributacao;
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
      itensForValidation.des_trib_ent = data[0].des_tributacao;
    }
  }
  if (CST === '40') {
    const {
      data: { data },
    } = await api.post('/buscaTributacao', {
      val_icms: 0,
      cod_situacao_tributaria: '40',
      val_reducao: 0,
    });
    if (data && data.length > 0) {
      vCodTrib = data[0].cod_tributacao;
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
      itensForValidation.des_trib_ent = data[0].des_tributacao;
    }
  }
  // AQUI
  if (CST === '70') {
    let CliValidaTribut: any;
    if (Number(pRedBC) !== 0) {
      const {
        data: { data },
      } = await api.post('/buscaTributacao', {
        val_icms: pICMS,
        cod_situacao_tributaria: '60',
        val_reducao: parseFloat(Number(pRedBC).toFixed(2)),
      });
      CliValidaTribut = data;
    } else {
      const {
        data: { data },
      } = await api.post('/buscaTributacao', {
        val_icms: pICMSST,
        cod_situacao_tributaria: '60',
        val_reducao: parseFloat(Number(pRedBCST).toFixed(2)),
      });
      CliValidaTribut = data;
    }
    if (CliValidaTribut === undefined || CliValidaTribut.length <= 0) {
      if (Number(pRedBC) !== 0) {
        const {
          data: { data },
        } = await api.post('/buscaTributacao', {
          val_icms: pICMS,
          cod_situacao_tributaria: '60',
          val_reducao: 100 - parseFloat(Number(pRedBC).toFixed(2)),
        });
        CliValidaTribut = data;
      } else {
        const {
          data: { data },
        } = await api.post('/buscaTributacao', {
          val_icms: pICMSST,
          cod_situacao_tributaria: '60',
          val_reducao: 100 - parseFloat(Number(pRedBC).toFixed(2)),
        });
        CliValidaTribut = data;
      }

      if (CliValidaTribut === undefined || CliValidaTribut.length <= 0) {
        itensForValidation.statusDaValidacao.push({
          codStatus: 300,
          messageStatus: 'Tributação do XML não encontrada(CST: 060)',
          origem: 'TRIB',
          restricao: 'BLOQ',
        });
      } else {
        vCodTrib = CliValidaTribut[0].cod_tributacao;
        itensForValidation.cod_trib_entrada = Number(vCodTrib);
        itensForValidation.des_trib_ent = CliValidaTribut[0].des_tributacao;
        const QryVerifProdTrib = await api.post('/buscaTributacaoEntrada', {
          cod_loja: dadosDaCapa.cod_loja,
          des_sigla: dadosDaCapa.des_uf,
          num_ncm: itensForValidation.num_ncm,
          cod_produto: itensForValidation.cod_produto,
        });
        if (
          QryVerifProdTrib.data.data &&
          QryVerifProdTrib.data.data.length <= 0
        ) {
          itensForValidation.statusDaValidacao.push({
            codStatus: 301,
            messageStatus: `Tributação do XML não esta vinculada ao NCM do Produto. - Cód Tributação : ${CliValidaTribut.cod_tributacao}`,
            origem: 'TRIB',
            restricao: 'BLOQ',
          });
        } else if (
          QryVerifProdTrib.data.data[0].cod_trib_entrada !==
          CliValidaTribut[0].cod_tributacao
        ) {
          itensForValidation.statusDaValidacao.push({
            codStatus: 302,
            messageStatus: `Tributação do XML difere da cadastrada no NCM - Cód Tributação: ${CliValidaTribut.cod_tributacao}`,
            origem: 'TRIB',
            restricao: 'BLOQ',
          });
        }
      }
    }
  }

  // Não achou nenhuma tributação retorna a primeira que encontrar do tipo(Tributado ou Substituição)
  if (Number(vCodTrib) === 0) {
    if (Number(pICMSST) > 0 || CST === '60' || Number(vICMSST) > 0) {
      if (CST === '60') {
        vCodTrib = await retornaCodigoDaTributacao(String(vCodTrib), 3, '060');
        itensForValidation.cod_trib_entrada = Number(vCodTrib);
      } else {
        vCodTrib = await retornaCodigoDaTributacao(String(vCodTrib), 3, '');
        itensForValidation.cod_trib_entrada = Number(vCodTrib);
      }
    } else if (
      Number(pICMS) > 0 &&
      Number(itensForValidation.val_reducao_ent) > 0
    ) {
      vCodTrib = await retornaCodigoDaTributacao(String(vCodTrib), 2, '');
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
    } else if (Number(pICMS) > 0) {
      vCodTrib = await retornaCodigoDaTributacao(String(vCodTrib), 0, '');
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
    } else if (Number(pICMS) === 0) {
      vCodTrib = String(itensForValidation.cod_trib_entrada);
    }
    if (Number(vCodTrib) > 0) {
      itensForValidation.cod_trib_entrada = Number(vCodTrib);
    } else {
      itensForValidation.statusDaValidacao.push({
        codStatus: 306,
        messageStatus: 'Nenhuma tributação do mesmo tipo encontrada',
        origem: 'TRIB',
        restricao: 'BLOQ',
      });
    }
  }

  // O que é RECORD_PRODUTO.CFOP_ALTERADO = FALSE
  if (
    (parametrosValidacao.icms_st || parametrosValidacao.icms_op) &&
    Number(vCodTrib) > 0 &&
    itensForValidation.flg_cfop_alterado === false
  ) {
    let amostra = false;
    if (
      itensForValidation.cfop === '5911' ||
      itensForValidation.cfop === '6911'
    ) {
      amostra = true;
    }
    // const vTipoTrib = await retornaTipoDaTributacao(String(vCodTrib));
    // if (itensForValidation.flg_bonificado) {
    //   itensForValidation.cfop = await defineCFOP(
    //     0,
    //     true,
    //     true,
    //     false,
    //     itensForValidation,
    //     parametrosValidacao,
    //     dadosDaCapa,
    //     dadosErpDoFornecedor,
    //   );
    // } else {
    //   itensForValidation.cfop = await defineCFOP(
    //     vTipoTrib,
    //     true,
    //     false,
    //     false,
    //     itensForValidation,
    //     parametrosValidacao,
    //     dadosDaCapa,
    //     dadosErpDoFornecedor,
    //   );
    // }
  }

  if (itensForValidation.CEST !== '') {
    if (itensForValidation.CEST !== itensForValidation.num_cest) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 308,
        messageStatus: 'CEST divergente',
        origem: 'TRIB',
        restricao: 'INF',
      });
    }
  }

  // Loja.FlgBloqConfImpXML = 'S' não existe
  if (
    parametrosValidacao.icms_st === false &&
    parametrosValidacao.icms_op === false
  ) {
    if (
      !(await existeTributacaoDoNCMUF(
        dadosErpDoFornecedor.des_uf.value,
        itensForValidation.cod_ncm,
      ))
    ) {
      itensForValidation.statusDaValidacao.push({
        codStatus: 305,
        messageStatus: `Tributação do estado do fornecedor, para o tipo de item ${itensForValidation.des_tipo_item} não informado no cadastro do NCM`,
        origem: 'TRIB',
        restricao: 'BLOQ',
      });
    }
  }

  return itensForValidation;
}
