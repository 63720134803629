export const corrigirArrayDeObjetos = (array: any[]): any[] => {
  return array.map((objeto) => {
    const objetoCorrigido: any = {};
    Object.keys(objeto).forEach((chave) => {
      const valor = objeto[chave];
      if (
        valor &&
        typeof valor === 'object' &&
        Object.keys(valor).length === 0
      ) {
        objetoCorrigido[chave] = '';
      } else {
        objetoCorrigido[chave] = valor;
      }
    });
    return objetoCorrigido;
  });
};
