import api from '~/services/api';
import {
  DadosDoProdutoDoERP,
  DataToInsertItems,
  ProductDataToTable,
} from '../../protocols';
import defineCFOP from './defineCFOP';
import validaGtinVinculado from './validaGtinVinculado';
import { verificarValor } from './verificarValor';

export default async function formataItensParaTabela({
  item,
  fornecedor,
  parametrosValidacao,
  dadosDaCapa,
  dadosErpDoFornecedor,
  loja,
  setStatus,
}: DataToInsertItems): Promise<ProductDataToTable> {
  /**
   * Dados padrão
   */
  let amostra = false;
  let formatedItem = {
    index: item.index,
    cod_loja: loja.cod_loja,
    cod_fornecedor: fornecedor.cod_fornecedor,
    cProd: item.xmlCProd,
    cEAN: item.xmlCean,
    cEANTrib: item.xmlCeanTrib,
    xProd: item.xProd,
    uCom: item.xmlUCom,
    uTrib: item.xmlUTrib,
    pICMS: item.xmlpICMS,
    pRedBCST: item.xmlpRedBCST,
    pMVAST: item.xmlpMVAST,
    pST: item.xmlpST,
    CEST: item.xmlCEST,
    NCM: item.xmlNCM,
    icsmCST: item.xmlIcmsCST,
    pRedBC: item.xmlpRedBC,
    pICMSST: item.xmlpICMSST,
    vProd: item.xmlvProd,
    vBC: item.xmlvBC,
    xmlCFOP: item.xmlCfop,
    xmlCST: String(item.xmlIcmsCST),
    vICMSST: String(item.xmlvICMSST),
    perfil: String(item.perfil),
    lojaUF: loja.uf,
    cfop: '',
    idCtb: '',
    CSOSN: item.xmlCSOSN,
    associado: '',
    cod_gtin_principal: item.erpCodGtin,
    cod_ncm: 0,
    cod_produto: item.erpCodProduto,
    cod_similar: '',
    cod_sit_tributaria_ent: '',
    cod_sit_tributaria_saida: '',
    cod_trib_ent_ncm: 0,
    cod_trib_entrada: 0,
    cod_trib_saida: 0,
    des_produto: '',
    des_trib_ent: '',
    des_trib_saida: '',
    des_unidade_compra: '',
    des_unid_compra_forn: '',
    des_sigla: '',
    flg_bonificado: false,
    // flg_fora_linha: false,
    flg_gtin_vinculado: false,
    flg_inativo: false,
    flg_ipv: false,
    flg_nao_pis_cofins: false,
    flg_cfop_alterado: false,
    num_cest: '',
    num_ncm: '',
    per_cofins: 0,
    per_pauta_iva_ncm: 0,
    per_pis: 0,
    qtd_embalagem_compra: 0,
    qtd_embalagem_compra_un_trib: 0,
    qtd_unid_compra_forn: 0,
    tipo_especie: 0,
    des_tipo_item: '',
    tipo_nao_pis_cofins: '',
    tipo_trib_ent: 0,
    tipo_trib_saida: 0,
    val_icms_ent: 0,
    val_icms_saida: 0,
    val_reducao_ent: 0,
    val_reducao_saida: 0,
  } as ProductDataToTable;

  formatedItem.statusDaValidacao = [];
  let dadosDoProdutoDoERP: DadosDoProdutoDoERP[] = [
    {
      cod_produto: '',
      cod_gtin_principal: '',
      des_produto: '',
      qtd_embalagem_compra: 0,
      des_unidade_compra: '',
      cod_ncm: 0,
      num_ncm: '',
      des_trib_ent: '',
      des_trib_saida: '',
      val_pauta_ipv: 0,
      val_pauta_iva: 0,
      per_pauta_iva_ncm: 0,
      flg_veda_cred: false,
      cod_cest: 0,
      num_cest: '',
      per_fcp_ent: 0,
      per_fcp_saida: 0,
      flg_inativo: false,
      flg_fora_linha: false,
      flg_ipv: false,
      cod_similar: '',
      cod_associado: '',
      qtd_unid_compra_forn: 0,
      des_unid_compra_forn: '',
      tipo_especie: 0,
      flg_nao_pis_cofins: false,
      tipo_nao_pis_cofins: 0,
      cod_trib_entrada: 0,
      tipo_trib_ent: 0,
      cod_sit_tributaria_ent: '',
      val_icms_ent: 0,
      val_reducao_ent: 0,
      cod_trib_saida: 0,
      tipo_trib_saida: 0,
      cod_sit_tributaria_saida: '',
      val_icms_saida: 0,
      val_reducao_saida: 0,
      cod_trib_ent_ncm: 0,
      per_pis: 0,
      per_cofins: 0,
      qtd_embalagem_compra_un_trib: 0,
    },
  ];

  /**
   * Caso o erpCodGtin não tenha sido encontrado, entende-se que o item da NF não existe no ERP
   */

  if (item.erpCodGtin === '') {
    if (setStatus) {
      setStatus((prev) => [...prev, '- Item não encontrado no ERP.']);
    }
    formatedItem.statusDaValidacao = [
      {
        codStatus: 1,
        messageStatus: 'Produto não encontrado',
        origem: 'MIX',
        restricao: 'NE',
      },
    ];
    formatedItem = Object.assign(formatedItem, dadosDoProdutoDoERP[0]);
    return formatedItem;
  }
  /**
   * Caso o item exista no ERP
   */
  const {
    data: { data },
  } = await api.post('/erp-item', {
    des_uf_forn: fornecedor.uf,
    des_uf_loja: loja.uf,
    cod_fornecedor: fornecedor.cod_fornecedor,
    gtin: item.erpCodGtin,
    cod_loja: loja.cod_loja,
    des_unidade_compra: item.des_unidade_compra,
  });
  /**
   * Caso o item exista no ERP mas por algum motivo os dados não possam ser retornados
   */
  dadosDoProdutoDoERP = data;
  if (dadosDoProdutoDoERP === undefined || dadosDoProdutoDoERP.length <= 0) {
    if (setStatus) {
      setStatus((prev) => [...prev, '- Item não encontrado no ERP.']);
    }
    formatedItem.statusDaValidacao = [
      {
        codStatus: 1,
        messageStatus: 'Produto não encontrado',
        origem: 'MIX',
        restricao: 'NE',
      },
    ];
    formatedItem.cod_gtin_principal = '';
    formatedItem.cod_produto = '';
    formatedItem = Object.assign(formatedItem, dadosDoProdutoDoERP[0]);
    return formatedItem;
  }

  /**
   * Caso o item exista no ERP e os dados tenham sido retornados corretamente
   */
  if (setStatus) {
    setStatus((prev) => [...prev, '- Item encontrado no ERP.']);
  }
  formatedItem = Object.assign(formatedItem, dadosDoProdutoDoERP[0]);

  formatedItem.flg_bonificado = !!['910', '920'].includes(
    item.xmlCfop.substring(1, 4),
  );

  // A VALIDAR
  // if (formatedItem.qtd_unid_compra_forn > 0) {
  //   if (formatedItem.qtd_embalagem_compra_un_trib > 0) {
  //     formatedItem.qtd_embalagem_compra =
  //       formatedItem.qtd_embalagem_compra_un_trib;
  //     formatedItem.des_unidade_compra =
  //       item.xmlUCom !== 'KG' && item.xmlUTrib === 'KG'
  //         ? item.xmlUTrib
  //         : item.xmlUCom.substring(0, 2);
  //   } else {
  //     formatedItem.qtd_embalagem_compra = formatedItem.qtd_unid_compra_forn;
  //     formatedItem.des_unidade_compra = formatedItem.des_unid_compra_forn;
  //   }
  // }
  amostra = item.xmlCfop === '5911' || item.xmlCfop === '6911';

  formatedItem.flg_cfop_alterado = item.edicao !== false;

  if (formatedItem.flg_cfop_alterado === false || formatedItem.cfop === '') {
    if (formatedItem.flg_bonificado) {
      formatedItem.cfop = await defineCFOP(
        0,
        true,
        true,
        false,
        formatedItem,
        parametrosValidacao,
        dadosDaCapa,
        dadosErpDoFornecedor,
      );
      // formatedItem.cfop = defineCFOP(0, true, true, false);
    } else {
      formatedItem.cfop = await defineCFOP(
        formatedItem.tipo_trib_ent,
        false,
        false,
        amostra,
        formatedItem,
        parametrosValidacao,
        dadosDaCapa,
        dadosErpDoFornecedor,
      );
      // SatateItensCFOP.AsString := DefineCFOPProd(DadosProdutoTIPO_TRIB_ENT.AsInteger, True,False,Amostra);
    }
    // if (formatedItem.cod_trib_ent_ncm > 0) {
    //   formatedItem.des_trib_ent = formatedItem.des_trib_saida;
    // }
    formatedItem.flg_gtin_vinculado = await validaGtinVinculado(
      formatedItem.cod_produto,
      verificarValor(formatedItem.cEAN),
      verificarValor(formatedItem.cEANTrib),
    );
  }
  return formatedItem;
}
