/* eslint-disable no-lonely-if */
import api from '~/services/api';
import {
  CapaData,
  ParamentrosValidacao,
  DadosErpDoFornecedor,
  ProductDataToTable,
  CodFiscal,
} from '../../protocols';
import filtrarCFOP from './filtrarCFOP';

async function getCFOPS(perfil: string) {
  try {
    const res = await api.get(`/buscaCodigosFiscais/${perfil}`);

    const { data } = res.data;
    return data;
  } catch (error: any) {
    if (error.data && error.data.message) {
      return 0;
    }
    return 0;
  }
}

export default async function defineCFOP(
  tipoTributacao: number,
  flgImporta: boolean,
  flgBonificacao: boolean,
  flgAmostra: boolean,
  formatedItem: ProductDataToTable,
  parametrosValidacao: ParamentrosValidacao,
  dadosDaCapa: CapaData,
  dadosErpDoFornecedor: DadosErpDoFornecedor,
): Promise<string> {
  let codigosFiscais = {} as CodFiscal[];
  codigosFiscais = await getCFOPS(parametrosValidacao.cod_perfil);
  let filtros: any[] = [];

  const tipoTributo = tipoTributacao;
  if (tipoTributacao === -10) {
    return '';
  }

  if (formatedItem || flgImporta) {
    if (formatedItem.cod_produto !== '' || flgImporta) {
      if (dadosDaCapa.des_uf === dadosErpDoFornecedor.des_uf.value) {
        if (flgBonificacao) {
          filtros = [
            {
              type: 'startsWith',
              toFilter: ['191', '591', '192'],
            },
          ];
          // vExpressao:= '(CFOP LIKE ''191%'' OR CFOP LIKE ''591%'' OR CFOP LIKE ''192%''  )'
        } else if (flgAmostra) {
          filtros = [
            {
              type: 'equals',
              toFilter: ['1911'],
            },
          ];
          // vExpressao:= '(CFOP = ''1911'')'
        } else {
          filtros = [
            {
              type: 'startsWith',
              toFilter: ['1', '5'],
            },
            {
              type: 'diferent',
              toFilter: ['1910'],
            },
          ];
        }
      } else {
        if (flgBonificacao) {
          filtros = [
            {
              type: 'startsWith',
              toFilter: ['291', '292', '391', '691', '7'],
            },
          ];
          // vExpressao:= '(CFOP LIKE ''291%'' OR CFOP LIKE ''292%'' OR CFOP LIKE ''391%'' OR CFOP LIKE ''691%'' OR CFOP LIKE ''7%'')'
        } else if (flgAmostra) {
          filtros = [
            {
              type: 'equals',
              toFilter: ['2911'],
            },
          ];
          // vExpressao:= '(CFOP = ''2911'')'
        } else {
          filtros = [
            {
              type: 'startsWith',
              toFilter: ['2', '3', '6', '7'],
            },
            {
              type: 'diferent',
              toFilter: ['2910'],
            },
          ];
          // vExpressao:= '(CFOP LIKE ''2%'' OR CFOP LIKE ''3%'' OR CFOP LIKE ''6%'' OR CFOP LIKE ''7%'')';
          // vExpressao:= vExpressao+ ' AND (CFOP <> ''2910 '')';
        }
      }
      if (tipoTributo === 3 && flgAmostra === false) {
        filtros.push(
          {
            type: 'equals',
            flag: 'flg_separa_subst',
            toFilter: [true],
          },
          {
            type: 'equals',
            flag: 'flg_dif',
            toFilter: [true],
          },
        );
        // vExpressao:= vExpressao+ ' AND ((flg_separa_subst = ''S'') OR (FLG_DIF = ''S'')) '
      } else {
        filtros.push({
          type: 'equals',
          flag: 'flg_separa_subst',
          toFilter: [false],
        });
        // vExpressao:= vExpressao+ 'AND (flg_separa_subst = ''N'')';
      }
      // Aplica o filtro a variavel codigosFiscais
      // Filtered := True; --Aqui aplica a expressão (FIND) sobre o reusltset.
      let result = null;
      if (codigosFiscais && codigosFiscais.length > 0) {
        result = await filtrarCFOP(codigosFiscais, filtros);
      }
      if (tipoTributo === 3 && flgAmostra === false) {
        if (result) {
          const getCfopFromCodigosFiscais =
            result.find(
              (codigoFiscal) => codigoFiscal.flg_separa_subst === true,
            ) || result.find((codigoFiscal) => codigoFiscal.flg_dif === true);
          if (getCfopFromCodigosFiscais) {
            return getCfopFromCodigosFiscais.cfop;
          }
          return '';
        }
      }
      if (tipoTributo !== 3 || flgAmostra) {
        if (result) {
          const getCfopFromCodigosFiscais = result.find(
            (codigoFiscal) => codigoFiscal.flg_separa_subst === false,
          );
          if (getCfopFromCodigosFiscais) {
            return getCfopFromCodigosFiscais.cfop;
          }
          const getCfopFromCodigosFiscais2 = result.find(
            (codigoFiscal) =>
              codigoFiscal.flg_separa_subst === false ||
              codigoFiscal.flg_dif === true,
          );
          if (getCfopFromCodigosFiscais2) {
            return getCfopFromCodigosFiscais2.cfop;
          }
          return '';
        }
      }
    }
  }
  return '';
}
